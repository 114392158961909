import Loading from "@/components/loading";
import PartialError from "@/components/partial-error";
import {
  arrayToCSV,
  convertDailyMetricsToDataset,
  convertReportsToDataset,
  downloadCSV,
} from "@/helpers/csv";
import { eventBusEmit } from "@/helpers/event-bus";
import { Report, SessionsDailyMetric, Zone } from "@/interfaces";
import ReportsList from "@/pages/organization/dashboard/reports/list";
import { gql, useQuery } from "@apollo/client";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import {
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Title from "../components/title";
import { DateRangeSelector } from "../members/metrics";

const GET_EMPLOYEES = gql`
  query ListMembers {
    ListMembers {
      id
      firstName
      lastName
      accessRevokedAt
    }
  }
`;

const CSVDetailledButton = ({ dateRange, selectedZone, CSVFilename }) => {
  const { t } = useTranslation(["organization", "misc"]);
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Grid style={{ float: "right", marginRight: "1em" }}>
      <ButtonGroup color="secondary" size="small" variant="contained">
        <Button
          disabled={loading}
          onClick={() => {
            const variables = {
              startDate: dateRange.start,
              endDate: dateRange.end,
              zoneId: selectedZone?.id,
            };

            setLoading(true)
            const client = createApolloClient();
            client
              .query({
                query: LIST_SESSIONS_DAILY_METRICS,
                variables,
              })
              .then(({ data }) => {
                const filePayload = arrayToCSV(
                  convertDailyMetricsToDataset(t, data.ListSessionsDailyMetrics)
                );
                const filename = CSVFilename.join("-");
                downloadCSV({ data: filePayload, filename });
                setLoading(false)
              })
              .catch((error) => {
                console.error(error);
                setLoading(false)
              });
          }}
        >
          <SimCardDownloadIcon /> Download detailled report
        </Button>
      </ButtonGroup>
    </Grid>
  );
};

const CSVGlobalButton = ({ reports, CSVFilename }) => {
  const { t } = useTranslation(["organization", "misc"]);
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Grid style={{ float: "right", marginRight: "1em" }}>
      <ButtonGroup color="secondary" size="small" variant="contained">
        <Button
          disabled={loading}
          onClick={() => {
            setLoading(true)
            const data = arrayToCSV(convertReportsToDataset(t, reports));
            const filename = CSVFilename.join("-");
            downloadCSV({ data, filename });
            setLoading(false)
          }}
        >
          <SimCardDownloadIcon /> Download global report
        </Button>
      </ButtonGroup>
    </Grid>
  );
};

export default function ListReports() {
  const { t } = useTranslation(["organization", "misc"]);
  const [reports, setReports] = React.useState<Report[]>([]);
  const { data, error, loading } = useQuery(GET_EMPLOYEES);
  const [dailyMetrics, setDailyMetrics] = React.useState<SessionsDailyMetric[]>(
    []
  );
  const [members, setMembers] = useState<
    {
      id: string;
      firstName: string;
      lastName: string;
      accessRevokedAt: string;
    }[]
  >([]);
  const [selectedZone, setSelectedZone] = useState<
    Zone | { id?: string; name?: string }
  >({});
  const [dateSelector, setDateSelector] = React.useState<string>("");
  const [dateRange, setDateRange] = React.useState({
    start: new Date(),
    end: new Date(),
  });

  useEffect(() => {
    eventBusEmit({ type: "page-name", payload: t("reports.title") });
    eventBusEmit({ type: "right-menu", payload: <UpgradeButton /> });
  }, [t]);

  useEffect(() => {
    setReports([]);
  }, [dateSelector]);

  useEffect(() => {
    if (data) setMembers(data.ListMembers);
  }, [data, members]);

  if (error)
    return (
      <PartialError error={t("error.page-data-failure", { ns: "misc" })} />
    );
  if (loading) return <Loading />;

  const CSVFilename: string[] = ["reports"];

  if (selectedZone.id) {
    CSVFilename.push(slugify(selectedZone.name));
  }

  if (dateRange.start)
    CSVFilename.push(dateToReadableDayMonthYear(dateRange.start));
  if (dateRange.end)
    CSVFilename.push(dateToReadableDayMonthYear(dateRange.end));

  return (
    <React.Fragment>
      <DateRangeSelector
        dateSelector={dateSelector}
        setDateSelector={setDateSelector}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />

      <Divider variant="middle" sx={{ mb: 2 }} />

      <CSVDetailledButton
        dateRange={dateRange}
        selectedZone={selectedZone}
        CSVFilename={CSVFilename}
      />
      <CSVGlobalButton reports={reports} CSVFilename={CSVFilename} />

      <Title>{t("members.metrics.reports-title")}</Title>
      <Typography
        justifyContent="end"
        color="text.secondary"
        sx={{ flex: 1, mb: 2 }}
      >
        {t("members.metrics.reports-description")}
      </Typography>

      <FilterByZoneSelector
        setSelectedZone={setSelectedZone}
        selectedZone={selectedZone}
        onZoneClicked={() => {
          setReports([]);
        }}
      />

      <Grid container spacing={3}>
        {/* Members */}
        <Grid item xs={12}>
          <ReportsList
            zone={selectedZone}
            members={members}
            dateRange={dateRange}
            setReports={setReports}
            reports={reports}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const LIST_ZONES = gql`
  query ListZones {
    ListZones {
      id
      name
      kind
      geofencing
    }
  }
`;

import createApolloClient from "@/apollo-client";
import { UpgradeButton } from "@/components/upgrade-button";
import { slugify } from "@/helpers/format";
import { dateToReadableDayMonthYear } from "@/helpers/time";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { LIST_SESSIONS_DAILY_METRICS } from "../members/metrics/timesheet";

export function FilterByZoneSelector({
  setSelectedZone,
  selectedZone,
  onZoneClicked = () => {},
}) {
  const { t } = useTranslation(["organization", "misc"]);
  const [filterClicked, setFilterClicked] = useState<boolean>(false);
  const listZones = useQuery(LIST_ZONES);
  const [zones, setZones] = useState<Zone[]>([]);

  useEffect(() => {
    if (listZones.data) setZones(listZones.data.ListZones);
  }, [listZones.data]);

  // if there's only one zone or if it hasn't loaded yet
  // we just don't show that option
  if (zones.length <= 1) {
    return <></>;
  }

  if (!filterClicked) {
    return (
      <Grid>
        <ButtonGroup size="small" variant="text" className="mb-2">
          <Button
            onClick={() => {
              setFilterClicked(true);
            }}
          >
            <FilterAltIcon /> {t("members.metrics.filter-by-zone")}
          </Button>
        </ButtonGroup>
      </Grid>
    );
  }

  return (
    <Stack direction={{ xs: "column", md: "row" }} spacing={1} sx={{ mb: 2 }}>
      <Grid item xs={12}>
        {zones.map((zone) => (
          <Chip
            style={{ marginRight: 2, marginLeft: 2 }}
            label={zone.name}
            color={selectedZone.id === zone.id ? "primary" : "default"}
            onClick={() => {
              onZoneClicked();
              if (selectedZone.id === zone.id) {
                setSelectedZone({});
              } else {
                setSelectedZone(zone);
              }
            }}
          />
        ))}
      </Grid>
    </Stack>
  );
}
